<template>
    <el-drawer :title="title" :visible.sync="drawer" :direction="direction" :before-close="handleClose" destroy-on-close>

        <div style="border-top: 1px solid #e9e9e9"></div>
        <div class="companyList" v-if="false">
            当前公司：<el-select v-model="activeCompany" @change="companyChange" placeholder="请选择">
                <el-option
                v-for="item in CompanyList"
                :key="item.Id"
                :label="item.Title"
                :value="item.Id">
                </el-option>
            </el-select>
        </div>
        <div class="group-box" v-if="routerList.length"> 
            <el-checkbox-group v-model="capacityList">
                <el-checkbox :label="item.Id" :key="item.Id" v-for="(item) in routerList"> {{ item.GroupName }}</el-checkbox>
            </el-checkbox-group>
        </div>

        <div v-else-if="loading"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            ></div>

        <el-empty v-else description="暂无数据"></el-empty>

        <div style="height: 50px"></div>
        <div class="rightbtn">
            <el-button @click="drawer = false">取消</el-button>
            <el-button type="primary" :loading="btnLoading" @click="handleSubmit">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
const regExp = new RegExp('^1[3456789]\\d{9}$') // 手机号正则
let routerList = [];
export default {
    props: {
        parentObj: { type: Object },
    },
    components: {
    },
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            Id: '',
            entity: {},
            props: {
                label: 'GroupName',
                id: 'Id'
            },
            routerList,
            capacityList: [],
            rules: {},
            btnLoading: false,
            title: '',
            loading: false,
            timeout: null,
            companys: '',
            CompanyList:[],
            activeCompany:'',
        }
    },
    mounted() {
        // let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // this.getComputedList(userInfo.Id);
    },
    methods: {
        init() {
            this.entity = {}
            this.capacityList = [];
            this.drawer = true

            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            this.getComputedList(this.Id);
        },
        openForm(Id, CompanyId) {            
            if (CompanyId) {
                this.entity.SupplieId = CompanyId
            }
            this.entity.IsSubAccount = true
            if (Id) {
                this.title = '编辑数据权限'
                this.Id = Id;
            }
            this.init()
        },
        handleClose(done) {
            done()
        },
        handleSelect(item) {
            console.log(item)
        },
        getComputedList(id) {
            let that = this
            this.$http
                .post("/Base_Manage/Base_Company/GetDataCompanyData", {
                    Search: {
                        condition: "CreatorId",
                        keyword: id,
                    },
                })
                .then((res) => {
                    if (res.Success) {
                        if(res.Data.length){
                            res.Data.forEach(function (item, index, arr) {
                                that.companys += arr[index]['Id'] + ','
                                })
                            this.CompanyList = res.Data
                            this.CompanyList.unshift({
                                Id:that.companys,
                                Title: '全部'
                            })
                            this.getTheData(this.companys,id)
                        }
                    }
                });
        },
        getTheData(ids) {
            this.activeCompany = ids
            this.capacityList = []
            this.loading = true
            Promise.all([
                this.$http.post('/BO/CustomerinfoGroup/GetGroupDetail',{ Search:{ SupplieId:ids, Status:"0" }}),
                this.$http.post('/Base/base_usergroup/GetDataList ',{ Search:{ Condition: 'UserId', Keyword: this.Id}}),
            ]).then(res=>{
                if(res[0] && res[0].Success){
                    this.routerList = res[0].Data;
                } else {
                    this.$message.error(res[0].Msg)
                }
                if(res[1] && res[1].Success){
                    if(res[1].Data && res[1].Data.length){
                        this.capacityList = res[1].Data.map(e=>e.GroupId)
                    }
                }else{
                    this.$message.error(res[1].Msg)
                }
                
            }).finally(()=>{
                this.loading = false
            })           
            
        },
        handleSubmit() {
            let list = []
            if(this.capacityList && this.capacityList.length){
                list = this.capacityList.map(item=>{
                    return {
                        UserId: this.Id,
                        GroupId: item
                    }
                })
            }else{
                this.drawer = false;
                return
            }
            this.btnLoading = true
            this.$http.post('/Base/base_usergroup/SaveDataByList', list).then(res => {
                if (res.Success) {
                    this.$message.success('保存成功!');
                    this.drawer = false;
                } else {
                    this.$message.error('保存失败! ' + res.Msg);
                }
            }).catch(err => {
                this.$message.error(err);
            }).finally(()=>{
                this.btnLoading = false
            })
        },
        companyChange(e){
           this.getTheData(e, this.Id)
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-drawer__header {
    padding: 10px;
    margin-bottom:0;
}
.companyList{
    padding: 5px 0px 10px 20px;
}
.group-box {
    margin: 6px 20px;
}
.group-list {
    margin-top: 5px;
}
.rightbtn{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 5px 16px;
    background: #fff;
    text-align: right;
    z-index: 99999;
}
.el-checkbox {
    display: block;
    margin: 7px 0;
}
</style>