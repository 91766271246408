<template>
    <el-drawer :title="title" :visible.sync="drawer" :direction="direction" :before-close="handleClose" destroy-on-close
      custom-class="drawerClass">
      <div>
        <el-tree :props="props" :default-checked-keys="capacityList" node-key="path" :data="routerList" show-checkbox check-on-click-node
          @check="handleCheckChange">
        </el-tree>
      </div>
      <div style="height: 50px"></div>
      <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '5px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 99999,
        }">
        <el-button @click="drawer = false">取消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="handleSubmit">保存</el-button>
      </div>
    </el-drawer>
  </template>
  
  <script>
  import UploaderAvatar from '@/components/UploaderAvatar.vue'
  import CompanySearchSelect from '@/components/CompanySearchSelect.vue'
  const regExp = new RegExp('^1[3456789]\\d{9}$') // 手机号正则
  let routerList = [
    // {
    //   path: "/information",
    //   name: "账户",
    //   icon: "el-icon-reading",
    // },
    // { path: "/waybillCopy", name: "货单/服务", icon: "el-icon-tickets" },
    { path: "/waybill", name: "回执", icon: "el-icon-document-checked" },
    // {
    //   path: "/ArrearList",
    //   name: "对账记录",
    //   icon: "el-icon-document-copy",
    // },
    // {
    //   path: "/CollectMoney",
    //   name: "销账记录",
    //   icon: "el-icon-document-delete",
    // },
    // {
    //   path: "/Reconciliation",
    //   name: "对账函",
    //   icon: "el-icon-notebook-2",
    //   show: true,
    // },
    // {
    //   path: "/Overdue",
    //   name: "逾期",
    //   icon: "el-icon-paperclip",
    // },
    {
      path: "/remind",
      name: "提醒",
      icon: "el-icon-bell",
    },
    {
      path: "/group",
      name: "订阅",
      icon: "el-icon-share",
    },
    {
      path: "/chain",
      name: "存证",
      icon: "el-icon-receiving",
    },
    {
      path: "statistics",
      name: "统计",
      icon: "el-icon-receiving",
      // children:[{
      //   path: "/statistics",
      //   name: "发货统计",
      //   icon: "el-icon-receiving",
      // },
      // {
      //   path: "/statisticsSales",
      //   name: "收款统计",
      //   icon: "el-icon-receiving",
      // },
      // {
      //   path: "/statisticsSells",
      //   name: "销售统计",
      //   icon: "el-icon-receiving",
      // },
      // {
      //   path: "/statisticsBlance",
      //   name: "欠款统计",
      //   icon: "el-icon-receiving",
      // },
      // {
      //   path: "/statisticsOverdue",
      //   name: "逾期统计",
      //   icon: "el-icon-receiving",
      // },
      // {
      //   path: "/statisticsOverdueDays",
      //   name: "逾期天数统计",
      //   icon: "el-icon-receiving",
      // }]
    },
    {
      path: "/clienteleInfo",
      name: "客户",
      icon: "el-icon-phone-outline",
    },
    {
      path: "/clienteleInfo/group",
      name: "客户分组",
      icon: "el-icon-menu",
    },
    {
      path: "/clienteleInfo/solution",
      name: "查询方案",
      icon: "el-icon-s-opportunity",
    },
    {
      path: "/ContactPerson",
      name: "签收人",
      icon: "el-icon-user",
    },
    {
      path: "/GoodsInfo",
      name: "货品/服务",
      icon: "el-icon-s-finance",
    },
    {
      path: "/Carrier",
      name: "承运/服务方",
      icon: "el-icon-truck",
    },
    { path: "/domprint", name: "模板", icon: "el-icon-s-order" },
    {
      path: "/UserManagement",
      name: "子账户",
      icon: "el-icon-user-solid",
      show: true,
    },
  
    // { path: "/help", name: "帮助", icon: "el-icon-question" },
  ];
  export default {
    props: {
      parentObj: { type: Object },
    },
    components: {
      UploaderAvatar,
      CompanySearchSelect,
    },
    data() {
      return {
        drawer: false,
        direction: 'rtl',
        Id:'',
        entity: {},
        props: {
          label: 'name',
          id:'path'
        },
        routerList,
        capacityList:[],
        rules: {
          SupplieId: [
            { required: true, message: '请选择公司', trigger: 'change' },
          ],
          UserName: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          RealName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          UserPhone: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (!value) {
                  callback(new Error('请输入手机号'))
                } else if (!regExp.test(value)) {
                  callback(new Error('请输入正确的手机号'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          Sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        },
        btnLoading: false,
        title: '',
        loading: false,
        timeout: null,
      }
    },
    methods: {
      init() {
        this.entity = {}
        this.capacityList = [];
        this.drawer = true
        if (this.$refs['form']) {
          this.$refs['form'].clearValidate()
        }
      },
      openForm(Id, CompanyId) {
        this.init()
        if (CompanyId) {
          this.entity.SupplieId = CompanyId
        }
        this.entity.IsSubAccount = true
        if (Id) {
          this.title = '编辑菜单权限'
          this.getTheData(Id)
          this.Id = Id;
        }
      },
      handleClose(done) {
        done()
      },
      handleSelect(item) {
        console.log(item)
      },
      getTheData(id) {
        this.$http
          .post('/BO/base_subaccountpermissions/GetDataList',
            {
              "Search": {
                "Condition": "UserId",
                "Keyword": id
              }
            }
        )
          .then((res) => {
            if (res.Success) {
              this.capacityList = JSON.parse(res.Data[0].Permissions).map(item=>item.path);
            } else {
              this.$message.error(res.Msg)
            }
          })
      },
      handleCheckChange(data, checked, indeterminate) {
        console.log(checked.checkedNodes)
        let arr = [];
        checked.checkedNodes.forEach(item=>{
          arr.push(item.path);
        })
        this.capacityList = arr;
      },
      handleSubmit() {
        let arr = [];
        this.capacityList.forEach(ite => {
          this.routerList.forEach(item => {
            if (ite===item.path){
              arr.push(item);
            }
          })
          })
        this.$http.post('/BO/base_subaccountpermissions/SaveData', {
          "UserId": this.Id,
          "Permissions": JSON.stringify(arr)
        }).then(res=>{
          if(res.Success){
            this.$message.success('保存成功!');
            this.drawer=false;
          }else{
            this.$message.error('保存失败! '+res.Msg);
          }
        }).catch(err=>{
          this.$message.error(err);
        })
      },
    },
  }
  </script>
  
  <style>
  .drawerClass {
    padding: 10px;
  }
  </style>
  